<template>
  <div
    class="shadow-xl rounded-xl w-3/4 flex flex-col gap-6 p-8 justify-center mx-auto mt-8"
  >
    <div class="">
      <h1 class="text-2xl text-blue-dark font-bold capitalize">{{ title }}</h1>
      <p class="text-black mt-2">{{ textBody }}</p>
    </div>
    <div class="flex justify-between items-center gap-6">
      <BaseDate :label="labels.labelDate" :disabled="true" v-model="date" />
      <BaseInput :label="labels.labelInput" :disabled="true" v-model="name" />
    </div>

    <a-button
      type="primary"
      shape="round"
      class="w-3/4 mx-auto mt-4"
      @click="$router.push(redirectTo)"
      >ENTENDIDO</a-button
    >
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: {
    date: {
      type: Object || String,
      default: function () {
        return moment();
      },
      requiered: true,
    },
    name: { type: String, default: "Name" },
    title: { type: String, default: "Title component" },
    textBody: {
      type: String,
      default:
        " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis itaque numquam deserunt consequatur expedita quae vitae omnis ipsum atque, esse,  minima ad doloribus voluptatum ea accusamus ex, impedit fuga quasi?",
    },
    labels: {
      type: Object,
      default: function () {
        return { labelDate: "Fecha", labelInput: "Company" };
      },
    },
    redirectTo: {
      type: String,
      default: "/",
    },
  },
};
</script>
