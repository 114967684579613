<template>
  <EndRegistration
    :name="$route.query.company_name"
    :date="$route.query.creation_date"
    title="Recomendación diligenciada con éxito"
    textBody="La recomendación gestionada se ha registrado con éxito y será archivado en el historial  de infomes del Defensor del Consumidor Financiero. La recomendación se a registrado sobre esta fecha y entidad:"
    :labels="{ labelDate: 'Fecha del informe', labelInput: 'Entidad' }"
    redirectTo=".."
  />
</template>
<script>
import EndRegistration from "@/components/Dashboard/FCO/EndRegistration.vue";

export default {
  name: "endRecommendation",
  components: { EndRegistration },
};
</script>
